import React from "react"
import Layout from "../components/Layout"
import Policy from '../components/Policy';

const Returns = () => {
  return (
    <Layout>
    <Policy />
    </Layout>
  )
}

export default Returns
